import Landing from "../components/Landing";
import Footer from '../components/Footer';
import BackToTop from '../components/BackToTop';
import Funds from '../components/Funds';
import PageHeader from "../components/PageHeader";
import AdminNavbar from "./AdminNavbar";
import Navbar from '../components/Navbar';


function AdminAbout() {
    return (
        <>
        
            <AdminNavbar />
            <PageHeader title="About Us" path="/adminAbout" name="About Us" />
            <Landing />
            <Footer />
            
        </>
    );
}
export default AdminAbout;