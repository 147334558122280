import React, { useEffect, useState } from 'react';
import AdminNavbar from '../Admin/AdminNavbar'; // Import the AdminNavbar
import '../css/VolunteerList.css';
import axiosURL from '../axiosURLConfig';

// The main VolunteerList component
const AllDonars = () => {
    const [donars, setDonars] = useState([]); // State to hold volunteer data

    // Fetching the volunteers data when the component mounts
    useEffect(() => {
        axiosURL.get('/donations/getAllDonars')
            .then(response => {
                setDonars(response.data); // Set the volunteer data
            })
            .catch(error => {
                console.error('There was an error fetching the volunteers data!', error);
            });
    }, []);

    // Render the component
    return (
        <>
            {/* Admin Navbar Component */}
            <AdminNavbar />

            {/* Main Content Wrapper */}
            <div className="container mt-4">
                <h1 className="text-center mb-4">All Donars List</h1>

                {/* Volunteers Table */}
                <table className="table table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th> Name</th>
                            <th>Mobile No</th>
                            <th>Email</th>
                            <th>Amount</th>
                            <th>Message</th>
                            <th>Rzp-PaymentId</th>
                        </tr>
                    </thead>
                    <tbody>
                        {donars.map(donar => (
                            <tr key={donar.id}>
                                <td>{donar.name}</td>
                                <td>{donar.contact}</td>
                                <td>{donar.email}</td>
                                <td>{donar.amount}</td>
                                <td>{donar.message}</td>
                                <td>{donar.rzpPaymentId}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default AllDonars;
