import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navbar.css';
import image1 from '../assets/Images/LOGO_final_123_processed-removebg-preview (3).png'; 

export default function Navbar({ user, admin }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const path = useLocation().pathname;
    const navigate = useNavigate();

    const handleSignOut = () => {
        // Clear user information from local storage or context
        localStorage.removeItem('token'); // Assuming you're storing the token
        navigate('/'); // Redirect to the home page
    };

    return (
        <>
            {/* Nav Bar Start */}
            <div className="navbar navbar-expand-lg bg-dark navbar-dark">
                <div className="container-fluid">
                    <img src={image1} alt="Logo" className="navbar-logo" />
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav ml-auto">
                            <Link to="/" className={`nav-item nav-link ${path === '/' ? "active" : ""}`}>Home</Link>
                            <Link to="/about" className={`nav-item nav-link ${path === '/about' ? "active" : ""}`}>About</Link>
                            <Link to="/gallery" className={`nav-item nav-link ${path === '/gallery' ? "active" : ""}`}>Gallery</Link>
                            <Link to="/events" className={`nav-item nav-link ${path === '/events' ? "active" : ""}`}>Events</Link>
                            <Link to="/services" className={`nav-item nav-link ${path === '/services' ? "active" : ""}`}>Services</Link>
                            <Link to="/trustees" className={`nav-item nav-link ${path === '/trustees' ? "active" : ""}`}>Trustees</Link>
                            <Link to="/contact" className={`nav-item nav-link ${path === '/contact' ? "active" : ""}`}>Contact</Link>
                        </div>
                        <Link to="/donateus" className="btn-secondary m-3">Donate Now</Link>
                        <Link to="/joinnow" className="btn-secondary m-3">Join Now</Link>
                        
                        {admin ? (
                            <span className="navbar-text">Hello {admin.firstName}</span>
                        ) : user ? (
                            <>
                                <span className="navbar-text">Hello {user.firstName}</span>
                                <button onClick={handleSignOut} className="btn-secondary m-3">Sign Out</button>
                            </>
                        ) : (
                            <>
                                <Link to="/login" className="btn-secondary m-3">Sign In</Link>
                                <Link to="/adminlogin" className="btn-secondary m-3"><i className="fa fa-user" aria-hidden="true"></i></Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {/* Nav Bar End */}
        </>
    );
}
