import React, { useState, useEffect } from 'react';
import axiosURL from '../axiosURLConfig';
import Navbar from '../components/Navbar';
import Image1 from '../assets/Images/SignUp.jpg'
import {Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function AdminSignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

    const [isDisabled, setIsDisabled] = useState(false);

    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

    useEffect(() => {
        axiosURL.get('/admins/adminCount')
            .then(response => {
                if (response.data >= 2) {
                    setIsDisabled(true);
                }
            })
            .catch(error => {
                console.error("There was an error checking the admin count!", error);
            });
    }, []);
    
    const handleRegister = async (e) => {
        e.preventDefault();
        if (isDisabled) {
            alert("Admin registration is disabled.");
            return;
        }
  
        const response = await axiosURL.post('/admins/signup', formData);
        try {
            if (response.status === 201) {
                alert('Admin registered successfully');
                console.log('Admin registered successfully');
                navigate('/AdminLogin');
              }
        } catch (error) {
            alert('Email is already in use !');
            console.error('Email is already in use !', error);
        }
       
         
    };

    return (
        <>
        <Navbar />
       <div className="signup-container">
        <div className="signup-image">
          <img src={Image1} alt="Description" />
          <p className="image-description">
            <b>
              <center>Feel free to use or modify this description to suit your needs.</center>
            </b>
          </p>
        </div>
        <div className="signup-form">
          <h3><center>Admin SignUp</center></h3>
          <form onSubmit={handleRegister}>
            <label><b>First Name</b></label>
            <input type="text" name="firstName" placeholder="First Name" onChange={handleChange}  disabled={isDisabled}/>
            <label><b>Last Name</b></label>
            <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} />
            <label><b>Email</b></label>
            <input type="email" name="email" placeholder="Email" onChange={handleChange} />
            <label><b>Password</b></label>
            <input type="password" name="password" placeholder="Password" onChange={handleChange} />
            <label><b>Confirm Password</b></label>
            <input type="password" name="confirmPassword" placeholder="Confirm Password" onChange={handleChange} />
            <button type="submit">Sign Up</button>
          </form>
          <p>Already have an account? <Link className='loginbtn' to="/AdminLogin">Login</Link></p>
        </div>
      </div>
      <Footer />
        </>
    );
}

export default AdminSignUp;
