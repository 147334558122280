import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/AdminLogin.css';
import Navbar from '../components/Navbar';
import axiosURL from '../axiosURLConfig';

const AdminLogin = () => {
  const[email, setEmail] = useState('');
  const[password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) =>{
    event.preventDefault();

    try{
      const response = await axiosURL.post('/admins/adminLogin', {email, password});
      if(response.status === 201){
        alert('Login Successfully!...')
        localStorage.setItem('token', response.data.jwt); // Store the JWT token
        console.log('login Successfully');
        navigate('/AdminHome'); //Redirect to Admin dashboard or Admin home page
      }
      else{
        alert('Invalid Email or password !');
        console.log('Invalid Email or Password !');
        navigate('/AdminLogin'); //Redirect to Admin dashboard or Admin home page
      }
    } catch(error){
      if(error.response && error.response.status === 401){
        alert('Invalid Email or password !');
        console.log('Invalid Email or Password !');
      } else{
        alert('Login Error!');
      console.log('Login Error..', error);
      }
      
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          <h2>Admin Login</h2>
          <form onSubmit={handleSubmit}>
            <label><b>Email</b></label>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
            <label><b>Password</b></label>
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            <button type="submit" className="btn-secondary m-3">Login</button>
            <Link to="/admin-forgot-password" className="forgot-password">Forgot Password?</Link>
          </form>
          <p>Don't have an account? <Link className='signupbtn' to="/AdminSignUp">Sign Up</Link></p>

        </div>
      </div>
    </>
  );
}

export default AdminLogin;
