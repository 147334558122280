import React from 'react';
import ImageCard from '../components/ImageCard';
import PolicySection from '../components/PolicySection';
import privacyPolicyImage from '../assets/privacy_policy.svg';
import '../css/RefundCancellationPolicy.css';
import Navbar from './Navbar';
import Footer from './Footer';

const privacyPolicyContent = `
<ul>
    <li>Introduction - Overview of your NGO's commitment to privacy.</li>
    <li>Information Collection - Types of information collected.</li>
    <li>Use of Information - How collected information is used.</li>
    <li>Data Security - Measures taken to protect personal information.</li>
    <li>Data Retention - How long personal data is retained.</li>
    <li>Rights of Data Subjects - Rights of individuals regarding their personal data.</li>
    <li>Updates to this Policy - How and when the policy will be updated.</li>
    <li>Contact Information - How individuals can contact the NGO regarding privacy concerns.</li>
</ul>
`;

const PrivacyPolicy = () => {
    
    return (
        <>
        <Navbar/>
        <div className="content-container">
            <ImageCard
                image={privacyPolicyImage}
                title="Privacy Policy"
                text={privacyPolicyContent}
                id="privacy-policy"
            />
            <PolicySection
                title="Privacy Policy"
                content={privacyPolicyContent}
            />
        </div>
        <Footer/>
        </>
    );
};

export default PrivacyPolicy;
