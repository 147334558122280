import React, {useState} from 'react';
import '../css/Login.css';
import Navbar from '../components/Navbar';
import axiosURL from '../axiosURLConfig'

const AdminForgotPassword = () => {
  const [email, setEmail] = useState('');
 
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    try {
      const response = await axiosURL.post('/admins/admin-forgot-password', null, {
        params: { email }
      });
      console.log(response);
      alert('Check your email for the reset link!');
      setEmail('');// Clears the email field after submission
    } catch (error) {
      alert('Error sending reset link. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          <h2>Forgot Password</h2>
          <form onSubmit={handleSubmit}>
            <label><b>Email</b></label>
            <input 
              type="email" 
              placeholder="Enter your email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
            <button type="submit" className="btn-secondary m-3">Submit</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdminForgotPassword;

