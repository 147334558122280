// import React from 'react';
// import ImageCard from '../components/ImageCard';
// import PolicySection from '../components/PolicySection';
// import termsOfServiceImage from '../assets/term1.jpg';
// import '../css/RefundCancellationPolicy.css';
// import Navbar from './Navbar';
// import Footer from './Footer';

// const termsOfServiceContent = `
// <ul>
//     <li>Introduction - Explanation of the terms governing the use of your NGO's services/website.</li>
//     <li>Use of Services - Acceptable use of your NGO's services.</li>
//     <li>Donations and Contributions - Terms governing donations.</li>
//     <li>Intellectual Property - Ownership of content on the NGO’s website and materials.</li>
//     <li>Limitation of Liability - Disclaimer of warranties.</li>
//     <li>Governing Law - Jurisdiction and applicable law governing the terms.</li>
//     <li>Changes to Terms - How and when the terms will be updated.</li>
//     <li>Contact Information - How users can contact the NGO regarding questions or concerns about the terms.</li>
// </ul>

// `;

// const TermsOfService = () => {
//     return (
//         <>
//         <Navbar />
//         <div className="content-container">
//             <ImageCard
//                 image={termsOfServiceImage}
//                 title="Terms of Service"
//                 text={termsOfServiceContent}
//             />
//             <PolicySection
//                 title="Terms of Service"
//                 content={termsOfServiceContent}
//             />
//         </div>
//         <Footer/>
//         </>
//     );
// };

// export default TermsOfService;

import React from 'react';
import '../css/RefundCancellationPolicy.css';
import Navbar from './Navbar';
import Footer from './Footer';

const termsAndConditionsContent = `
<h3>Terms & Conditions</h3>
<p>Last updated on 16-11-2024 19:53:43</p>
<p>
    These Terms and Conditions, along with the privacy policy or other terms (“Terms”) constitute a binding agreement by and between SANT SEWALAL MAHARAJ FOUNDATION, 
    (“Website Owner” or “we” or “us” or “our”) and you (“you” or “your”) and relate to your use of our website, goods (as applicable), or services (as applicable) (collectively, “Services”).
</p>
<p>
    By using our website and availing the Services, you agree that you have read and accepted these Terms (including the Privacy Policy). We reserve the right to modify these Terms at any time and without assigning any reason. It is your responsibility to periodically review these Terms to stay informed of updates.
</p>
<ul>
    <li>To access and use the Services, you agree to provide true, accurate and complete information to us during and after registration, and you shall be responsible for all acts done through the use of your registered account.</li>
    <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials offered on this website or through the Services, for any specific purpose.</li>
    <li>Your use of our Services and the website is solely at your own risk and discretion. You are required to independently assess and ensure that the Services meet your requirements.</li>
    <li>The contents of the Website and the Services are proprietary to us, and you will not have any authority to claim any intellectual property rights, title, or interest in its contents.</li>
    <li>You acknowledge that unauthorized use of the Website or the Services may lead to action against you as per these Terms or applicable laws.</li>
    <li>You agree to pay us the charges associated with availing the Services.</li>
    <li>You agree not to use the website and/or Services for any purpose that is unlawful, illegal, or forbidden by these Terms, or Indian or local laws that might apply to you.</li>
    <li>You agree and acknowledge that the website and the Services may contain links to other third-party websites. On accessing these links, you will be governed by the terms of use, privacy policy, and such other policies of such third-party websites.</li>
    <li>You understand that upon initiating a transaction for availing the Services, you are entering into a legally binding and enforceable contract with us for the Services.</li>
    <li>You shall be entitled to claim a refund of the payment made by you in case we are not able to provide the Service. The timelines for such return and refund will be according to the specific Service you have availed or within the time period provided in our policies (as applicable).</li>
    <li>Notwithstanding anything contained in these Terms, the parties shall not be liable for any failure to perform an obligation under these Terms if performance is prevented or delayed by a force majeure event.</li>
    <li>These Terms and any dispute or claim relating to them, or their enforceability, shall be governed by and construed in accordance with the laws of India.</li>
    <li>All disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in Pune, Maharashtra.</li>
    <li>All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website.</li>
</ul>
`;

const TermsOfService = () => {
    return (
        <>
            <Navbar />
            <div className="content-container">
                <div className="terms-and-conditions">
                    <h2>Terms & Conditions</h2>
                    <div dangerouslySetInnerHTML={{ __html: termsAndConditionsContent }} />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TermsOfService;
