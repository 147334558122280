import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/navbar.css';
import image1 from '../assets/LOGO final 123_processed.jpg'; // Import the logo image

export default function AdminNavbar() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const path = useLocation().pathname;
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove JWT token or session identifier
        localStorage.removeItem('token');
        // Navigate to login page
        navigate('/AdminLogin');
    };

    // Check if the user is logged in
    const isLoggedIn = !!localStorage.getItem('token');

    return (
        <>
            {/* Nav Bar Start */}
            <div className="navbar navbar-expand-lg bg-dark navbar-dark">
                <div className="container-fluid">
                    <img src={image1} alt="Logo" className="navbar-logo" />
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav ml-auto">
                            <Link to="/adminHome" className={`nav-item nav-link ${path === '/adminHome' ? "active" : ""}`}>Home</Link>
                            <Link to="/adminAbout" className={`nav-item nav-link ${path === '/adminAbout' ? "active" : ""}`}>About</Link>
                            <Link to="/adminGallery" className={`nav-item nav-link ${path === '/adminGallery' ? "active" : ""}`}>Gallery</Link>
                            <Link to="/adminEvents" className={`nav-item nav-link ${path === '/adminEvents' ? "active" : ""}`}>Events</Link>
                            <Link to="/volunteerList" className={`nav-item nav-link ${path === '/volunteerList' ? "active" : ""}`}>Volunteers</Link>
                            <Link to="/donarsList" className={`nav-item nav-link ${path === '/donarsList' ? "active" : ""}`}>All Donar</Link>
                        </div>
                        
                        {/* Conditionally render Logout button if logged in */}
                        {isLoggedIn ? (
                            <button onClick={handleLogout} className="btn-secondary m-3">
                                Logout
                            </button>
                        ) : (
                            <Link to="/AdminLogin" className="btn-secondary m-3">
                                <i className="fa fa-user" aria-hidden="true"></i>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            {/* Nav Bar End */}
        </>
    );
}
