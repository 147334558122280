import React from 'react';
import ImageCard from '../components/ImageCard';
import PolicySection from '../components/PolicySection';
import refundPolicyImage from '../assets/Fund.jpg';
import '../css/RefundCancellationPolicy.css';
import Navbar from './Navbar';
import Footer from './Footer';

const refundPolicyContent = `
<ul>
    <li>All donations made to Sant Sewalal Maharaj Foundation are considered final and non-refundable unless otherwise determined by the NGO's management.</li>
    <li>Donors may request a refund of their donation within 7 days of the transaction if the donation was made in error or for any other valid reason. Refund requests beyond this period will not be considered.</li>
    <li>To request a refund, donors must contact <a href="mailto:support@santsewalalfoundation.org">support@santsewalalfoundation.org</a> and provide details of the donation (e.g., donation amount, date of donation, method of payment).</li>
    <li>Refunds will be processed using the original method of payment whenever possible. If the original payment method is not available, refunds may be issued by check or another method determined by the NGO.</li>
    <li>In exceptional circumstances, such as errors in processing donations or other valid reasons determined by the NGO, management reserves the right to refund donations beyond the specified period or under different conditions.</li>
    <li>For questions or concerns about this refund and cancellation policy, please contact <a href="mailto:support@santsewalalfoundation.org">support@santsewalalfoundation.org</a>.</li>
    <li>This refund and cancellation policy may be updated from time to time. Any changes will be posted on <a href="http://www.santsewalalfoundation.org">www.santsewalalfoundation.org</a> and will be effective immediately upon posting.</li>
</ul>
`;

const RefundPolicy = () => {
    return (
        <>
        <Navbar/>
        <div className="content-container">
            <ImageCard
                image={refundPolicyImage}
                title="Refund Policy"
                text={refundPolicyContent}
                id="refund-policy"
            />
            <PolicySection
                title="Refund Policy"
                content={refundPolicyContent}
            />
        </div>
        <Footer/>
        </>
    );
};

export default RefundPolicy;
