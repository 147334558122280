import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contactus from "./components/Contactus";
import JoinNow from "./components/Joinnow";
import Gallery from "./pages/Gallery";
import Footer from './components/Footer.js';
import Events from './pages/Events';
import Trustees from "./pages/Trustees.js";
import DonateUs from "./pages/DonateUs.js";
import SignUp from "./components/SignUp.js";
import Login from "./components/Login.js";
import AdminLogin from "./Admin/AdminLogin.js";
import AdminPanel from "./Admin/AdminPanel.js";
import RefundCancellationPolicy from "./pages/RefundCancellationPolicy.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import VolunteerList from "./components/VolunteerList.jsx";
import DonationPaymentForm from "./pages/DonationPaymentForm.jsx";

import AdminHome from "./Admin/AdminHome.jsx";
import AdminAbout from "./Admin/AdminAbout.jsx";
import AdminGallery from "./Admin/AdminGallery.jsx";
import AdminEvents from "./Admin/AdminEvents.jsx";
import AllDonars from "./Admin/AllDonars.jsx";
import ForgotPassword from './components/ForgotPassword';
import AdminForgotPassword from "./Admin/AdminForgotPassword.jsx";
import AdminSignUp from "./Admin/AdminSignUp.jsx";
import RefundPolicy from "./components/RefundPolicy.js";
import CancellationPolicy from "./components/CancellationPolicy.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import TermsOfService from "./components/TermsOfService.js";
import Services from "./components/Services.js";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// Import AuthProvider
import { AuthProvider } from "./pages/AuthContext.js"; // Adjust the path as needed
// Scroll to top on route change
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ScrollToTop /> {/* Add this to scroll to the top on route change */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/adminHome" element={<AdminHome />} />
          <Route exact path="/adminAbout" element={<AdminAbout />} />
          <Route exact path="/adminGallery" element={<AdminGallery />} />
          <Route exact path="/adminEvents" element={<AdminEvents />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contactus />} />
          <Route exact path="/joinnow" element={<JoinNow />} />
          <Route exact path="/volunteerList" element={<VolunteerList />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/trustees" element={<Trustees />} />
          <Route path="/DonateUs" element={<DonateUs />} />
          <Route path="/donationPaymentForm" element={<DonationPaymentForm />} />
          <Route exact path="/donarsList" element={<AllDonars />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/AdminLogin" element={<AdminLogin />} />
          <Route path="/AdminPanel" element={<AdminPanel />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/events" element={<Events />} />
          <Route path="/RefundCancellationPolicy" element={<RefundCancellationPolicy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/admin-forgot-password" element={<AdminForgotPassword />} />
          <Route path="/AdminSignUp" element={<AdminSignUp />} />
          <Route path="/RefundPolicy" element={<RefundPolicy />} />
          <Route path="/CancellationPolicy" element={<CancellationPolicy />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsOfService" element={<TermsOfService />} />
          <Route path="/Services" element={<Services />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}
