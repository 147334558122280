import React from 'react';
import "../css/Services.css";
import ambulance from "../assets/Events/ambulance.jpeg";
import Navbar from './Navbar';
import Footer from './Footer';

const Services = () => {
  return (
    <>
    <Navbar />
    <section className="services">
      <div className="services-container">
        <h2 className="services-title">Ambulance Services</h2>
        
        <div className="services-content">
          {/* Image of an Ambulance */}
          <img 
            src= {ambulance}
            alt="Ambulance" 
            className="services-image"
           
          />

          {/* Description and contact info */}
          <div className="services-details">
            <p className="services-description">
              We provide timely and reliable ambulance services to ensure that those in need receive immediate medical attention. Our team is available 24/7 to respond to emergencies and transport patients safely to healthcare facilities.
            </p>
            <div className="services-contact">
              <p>
                <strong>Contact Number:</strong> <a href="tel:+919834030441">9834030441</a>
              </p>
              <p>
                <strong>Email:</strong> <a href="mailto:info@santsewalalfoundation.org">info@santsewalalfoundation.org</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section">
        <Footer />
        
    </div>
    
    </section>
    
    </>
  );
};

export default Services;
