import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Gallery.css';
import localImage1 from '../assets/Images/img1.jpg';
import localImage2 from '../assets/Images/img2.jpg';
import localImage3 from '../assets/Images/foodnew.jpeg';
import localImage4 from '../assets/Images/img4.jpg';
import localImage5 from '../assets/Images/img5new.jpg';
import localImage6 from '../assets/Images/img6new.jpg';
import localImage7 from '../assets/Images/hospital.jpg';
import localImage8 from '../assets/Images/dance.webp';
import localImage9 from '../assets/Images/sportevent2.jpg';
import localImage10 from '../assets/Gallery_Live/night_dress_1.jpg';
import localImage11 from '../assets/Gallery_Live/night_dress_2.jpg';
import localImage12 from '../assets/Gallery_Live/night_dress_3.jpg';
import localImage13 from '../assets/Gallery_Live/night_dress_4.jpg';
import localImage14 from '../assets/Gallery_Live/night_dress_5.jpg';
import localImage15 from '../assets/Gallery_Live/night_dress_6.jpg';
import localImage16 from '../assets/Gallery_Live/night_dress_7.jpg';
import localImage17 from '../assets/Gallery_Live/night_dress_8.jpg';
import localImage18 from '../assets/Gallery_Live/night_dress_9.jpg';
import localImage19 from '../assets/Gallery_Live/night_dress_10.jpg';
import localImage20 from '../assets/Gallery_Live/night_dress_11.jpg';
import localImage21 from '../assets/Gallery_Live/night_dress_12.jpg';
import localImage22 from '../assets/Gallery_Live/night_dress_13.jpg';
import localImage23 from '../assets/Gallery_Live/night_dress_14.jpg';
import localImage24 from '../assets/Gallery_Live/night_dress_15.jpg';
import localImage25 from '../assets/Gallery_Live/night_dress_mam.jpg';
import localImage26 from '../assets/Gallery_Live/night_dress_new_mam.jpg';
import localImage27 from '../assets/Gallery_Live/food_distribution_baby.jpg';
import localImage28 from '../assets/Gallery_Live/bag_tent3.jpg';
import localImage29 from '../assets/Gallery_Live/bag1.jpg';
import localImage30 from '../assets/Gallery_Live/bagtent4.jpg';
import Footer from '../components/Footer';
import AdminNavbar from './AdminNavbar';

const images = [
  { src: localImage1, title: 'One to One Guidance'},
  { src: localImage2, title: 'Rural Education' },
  { src: localImage3, title: 'Nutritious Food' },
  { src: localImage4, title: 'Practice Session' },
  { src: localImage5, title: 'Yoga Session' },
  { src: localImage6, title: 'Provide Clothes' },
  { src: localImage7, title: 'Hospitality' },
  { src: localImage8, title: 'Dance' },
  { src: localImage9, title: 'Sport' },
  { src: localImage11, title: 'Provide Clothes' },
  // { src: localImage12, title: 'Sport' },
  { src: localImage13, title: 'Provide Clothes' },
  // { src: localImage14, title: 'Sport' },
  { src: localImage15, title: 'Provide Clothes' },
  // { src: localImage16, title: 'Sport' },
  { src: localImage17, title: 'Provide Clothes' },
  // { src: localImage18, title: 'Sport' },
  // { src: localImage19, title: 'Sport' },
  { src: localImage20, title: 'Provide Clothes' },
  // { src: localImage21, title: 'Sport' },
  // { src: localImage22, title: 'Sport' },
  // { src: localImage23, title: 'Sport' },
  // { src: localImage24, title: 'Sport' },
  { src: localImage25, title: 'Clothes Distrubution ' },
  { src: localImage26, title: 'Provide Clothes with mam' },
  { src: localImage27, title: 'Provide Clothes with Baby' },
  { src: localImage28, title: 'Distributed Bags to Students' },
  { src: localImage29, title: 'Bag Distrubition' },
  { src: localImage30, title: 'Provide bags to Girls' }
];

const AdminGallery = () => {
  return (
    <div>
      <AdminNavbar /> {/* Include Navbar component */}
      <div className="container mt-5 pt-5" id="containerid">
        <h2 className="text-center mb-4">Image Gallery</h2>
        <div className="row">
          {images.map((image, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4" key={index}>
              <div className="hovereffect">
                <img src={image.src} className="img-fluid" alt={`Image ${index + 1}`} />
                <div className="overlay">
                  <h2>{image.title}</h2>
                  <a className="info" href="DonateUs">Donate Now</a>
                </div>
              </div>
            </div>
          )
          )
          
          }
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminGallery;

