import React from 'react';
import ImageCard from '../components/ImageCard';
import PolicySection from '../components/PolicySection';
import cancellationPolicyImage from '../assets/cancel.png';
import '../css/RefundCancellationPolicy.css';
import Navbar from './Navbar';
import Footer from './Footer';

const cancellationPolicyContent = `
<ul>
    <li>Cancellation of Recurring Donations</li>
    <li>Donors who have set up recurring donations may cancel future donations at any time by contacting <a href="mailto:support@santsewalalfoundation.org">support@santsewalalfoundation.org</a>.</li>
    <li>Cancellation requests for recurring donations must be received at least 15 days before the next scheduled donation date to ensure that the cancellation can be processed before the next payment is made.</li>
</ul>
`;

const CancellationPolicy = () => {
    return (
        <>
        <Navbar/>
        <div className="content-container">
            <ImageCard
                image={cancellationPolicyImage}
                title="Cancellation Policy"
                text={cancellationPolicyContent}
                id="cancellation-policy"
            />
            <PolicySection
                title="Cancellation Policy"
                content={cancellationPolicyContent}
            />
        </div>
        <Footer/>
        </>
    );
};

export default CancellationPolicy;
