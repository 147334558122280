import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import RefundPolicy from '../components/RefundPolicy'; // Import your RefundPolicy component
import CancellationPolicy from '../components/CancellationPolicy'; // Import your CancellationPolicy component
import PrivacyPolicy from '../components/PrivacyPolicy'; // Import your PrivacyPolicy component
import TermsOfService from '../components/TermsOfService'; // Import your TermsOfService component
import '../css/RefundCancellationPolicy.css';

const RefundCancellationPolicy = () => {
    return (
        <div>
            <Navbar />
            <div className="content-container">
                {/* Refund Policy */}
                <RefundPolicy />
                
                {/* Cancellation Policy */}
                <CancellationPolicy />

                {/* Privacy Policy */}
                <PrivacyPolicy />

                {/* Terms of Service */}
                <TermsOfService />
            </div>
            <Footer />
        </div>
    );
};

export default RefundCancellationPolicy;
