import React, { useEffect, useState } from 'react';
import '../css/AdminPanel.css';
import { useNavigate } from 'react-router-dom';
import axiosURL from '../axiosURLConfig';
import AdminNavbar from '../Admin/AdminNavbar';

const AdminPanel = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosURL.get('/admins/getAllAdmin'); // Correct API endpoint
                setData(response.data);
            } catch (error) {
                console.log('Error fetching admin dashboard', error);
                if (error.response && error.response.status === 401) {
                    navigate('/adminLogin');
                } else {
                    alert('Failed to fetch admin data.');
                }
            }
        };

        fetchData();
    }, [navigate]);

    return (
        <>
        <AdminNavbar />
        <div className="dashboard">
            <h2>Admin Dashboard</h2>
        </div>
        </>
    );
}

export default AdminPanel;
