import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Login.css';
import Navbar from './Navbar';
import axiosURL from '../axiosURLConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear previous errors
    setLoading(true); // Set loading state to true

    // Basic validation
    if (!email || !password) {
      setError('Email and Password are required.');
      setLoading(false);
      return;
    }

    try {
      const response = await axiosURL.post('/user/login', { email, password });
      console.log('API Response:', response); // Log the full API response for debugging

      // Check for successful login based on response data
      if (response.status === 201 && response.data && response.data.message === 'signin Success') {
        alert('Login Successfully!');
        console.log('Login Successfully');
        navigate('/'); // Redirect to User dashboard or User home page
      } else {
        // Handle unexpected responses or failed login attempts
        setError('Incorrect Credentials, please try again.');
        console.log('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Login error:', error); // Log the error for debugging
      setLoading(false); // Reset loading state

      if (error.response) {
        // Handle errors based on response status
        if (error.response.status === 401) {
          setError('Invalid Email or Password!'); // Set error message for invalid credentials
          console.log('Invalid Email or Password!');
        } else {
          setError('Login Error! Please try again.'); // Set error message for other errors
          console.log('Login Error:', error);
        }
      } else {
        // Handle network or other errors
        setError('Network Error! Please check your connection.');
        console.log('Network Error:', error);
      }
    } finally {
      setLoading(false); // Reset loading state in all cases
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-container">
        <div className="login-form">
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <label><b>Email</b></label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required // Adding required attribute
            />
            <label><b>Password</b></label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required // Adding required attribute
            />
            <button type="submit" className="btn-secondary m-3" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'} {/* Change button text based on loading state */}
            </button>
            {error && <p className="error-message">{error}</p>} {/* Display error messages */}
            <Link to="/forgot-password" className="forgot-password">Forgot Password?</Link>
          </form>
          <p>Don't have an account? <Link className='signupbtn' to="/signup">Sign Up</Link></p>
        </div>
      </div>
    </>
  );
}

export default Login;
